import http from 'http';
import https from 'https';

let httpsAgent: https.Agent;
let httpAgent: http.Agent;

export const getKeepAliveAgent = (url: string) => {
  if (url.includes('https')) {
    if (!httpsAgent) {
      httpsAgent = new https.Agent({
        rejectUnauthorized: false,
        keepAlive: true, // keep connection alive
      });
    }

    return {
      agent: httpsAgent,
    };
  }

  if (!httpAgent) {
    httpAgent = new http.Agent({
      keepAlive: true, // keep connection alive
    });
  }

  return {
    agent: httpAgent,
  };
};
