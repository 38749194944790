import * as Sentry from '@sentry/browser';
import type {
  CacheConfig,
  UploadableMap,
  RequestNode,
  Variables,
} from 'relay-runtime';

import {
  fetchWithRetries,
  getHeaders,
  getRequestBody,
  handleData,
  isMutation,
} from '@woovi/relay';

import { config } from '../config.tsx';
import { getKeepAliveAgent } from './getKeepAliveAgent.ts';

const PLATFORM = {
  APP: 'APP',
  WEB: 'WEB',
};

// Define a function that fetches the results of an request (query/mutation/etc)
// and returns its results as a Promise:
export const networkFetch = async (
  request: RequestNode,
  variables: Variables,
  cacheConfig: CacheConfig,
  uploadables: UploadableMap | null,
) => {
  const body = getRequestBody(request, variables, uploadables);

  const headers = {
    appplatform: PLATFORM.WEB,
    ...getHeaders(uploadables),
    version: 2, // enable 401
  };

  const isMutationOperation = isMutation(request);

  const fetchFn = isMutationOperation ? fetch : fetchWithRetries;

  try {
    const url = config.LOGIN_GRAPHQL_URL;

    const response = await fetchFn(url, {
      method: 'POST',
      headers,
      credentials: 'include',
      body,
      fetchTimeout: 30000,
      retryDelays: [3000, 5000],
      ...getKeepAliveAgent(url),
    });

    const data = await handleData(response);

    if (response.status === 401) {
      const [error] = data?.errors || [];

      throw new Error(error?.message || 'Expired token. Please log in again.', {
        cause: {
          code: 401,
        },
      });
    }

    if (isMutationOperation && data.errors) {
      throw data;
      // sink.error(data);

      // if (complete) {
      //   sink.complete();
      // }

      // throw data;
    }

    // TODO - improve GraphQL Error handler
    // https://github.com/1stdibs/relay-mock-network-layer/pull/6
    // if (response.status === 200 && Array.isArray(data.errors) && data.errors.length > 0) {
    //   sink.error(data.errors, true);
    //   sink.complete();
    //   return;
    // }

    if (!data.data) {
      Sentry.captureException(data.errors);

      throw data.errors;
      // sink.error(data.errors);
      // sink.complete();
      // return;
    }

    // sink.next(data);
    // sink.next({
    //   operation: request.operation,
    //   variables,
    //   response: data,
    // });

    // if (complete) {
    //   sink.complete();
    // }

    return data;
    // return {
    //   operation: request.operation,
    //   variables,
    //   response: data,
    // };
  } catch (err) {
    // eslint-disable-next-line
    console.log('err:', err);

    Sentry.captureException(err);

    // TODO - handle no successfull response after
    const timeoutRegexp = new RegExp(/Still no successful response after/);
    const serverUnavailableRegexp = new RegExp(/Failed to fetch/);

    if (
      timeoutRegexp.test(err.message) ||
      serverUnavailableRegexp.test(err.message)
    ) {
      throw new Error('Serviço indisponível. Tente novamente mais tarde.');
      // sink.error(new Error('Serviço indisponível. Tente novamente mais tarde.'));

      // throw new Error('Serviço indisponível. Tente novamente mais tarde.');
    }

    throw err;
    // sink.error(err);
    // throw err;
  }
};
